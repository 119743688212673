@import '../../styles/variables';
@import '../../styles/mixin';

.switch {
  border: 1px solid $med-rec-switch-border-color;
  border-radius: $med-rec-switch-border-radius;
  height: 40px;

  @include media(lg) {
    height: 48px;
  }

  &:hover,
  &:focus-within {
    border: 1px solid $med-rec-switch-border-color-hover;
  }

  .switch_container {
    position: relative;
    padding: 3px;
    border-radius: $med-rec-switch-border-radius;
    background: $med-rec-switch-background;
    display: flex;
    width: 100%;
    height: 100%;

    .switch__button {
      width: 56px;
      height: 32px;
      color: $label-btn-mode-med;
      font-weight: 500;
      z-index: 2;
      transition: color 0.2s ease-in-out;

      @include media(lg) {
        height: 40px;
        width: 64px;
      }

      &_selected {
        color: $label-btn-mode-rec;

        &:hover {
          border-radius: calc($med-rec-switch-border-radius - 2px);
          background: $background-btn-mode-rec-hover !important;
        }
      }

      &__alternate_header_variation {
        @media screen and (max-width: 1024px) {
          height: 30px;
        }
      }
    }

    .switch__selected_background {
      background: $background-btn-mode-rec;
      position: absolute;
      height: 32px;
      width: 56px;
      transform: translateX(0);
      border-radius: calc($med-rec-switch-border-radius - 2px);
      transition: transform 0.2s linear;

      @include media(lg) {
        height: 40px;
        width: 64px;
      }

      &.right_checked {
        transform: translateX(100%);
      }

      &__alternate_header_variation {
        @media screen and (max-width: 1024px) {
          height: 30px;
        }
      }
    }
  }

  &__alternate_header_variation {
    @media screen and (max-width: 1024px) {
      height: 38px;
    }
  }
}
