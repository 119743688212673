@import '../../styles/variables';
@import '../../styles/mixin';

.page_banner__section {
  @include media(sm) {
    margin-top: 20px;
  }

  @include media(lg) {
    margin-top: 26px;
  }

  @include media(xl) {
    margin-top: 32px;
  }

  @include media(2xl) {
    margin-top: 40px;
  }

  &_fullwidth {
    padding: 0;
    margin: 0;
  }

  .placeholder {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: $placeholder-bg;

    div {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      margin: 0 auto;
      height: 18px;
      width: 18px;

      @include media(3xl) {
        height: 30px;
        width: 30px;
      }
    }

    p {
      width: 100%;
      margin-top: 10px;
      text-align: center;
      font-size: $text-xs;
      color: $gray-300 !important;

      @include media(3xl) {
        font-size: $text-base;
      }
    }
  }
}

.page_banner__container {
  position: relative;
  background: inherit;

  @media screen and (max-width: 640px) {
    padding: 0;
    margin: 0;
  }

  .page_banner__mobile {
    position: relative;
    width: 100%;
    padding-top: 58.13%;
    overflow: hidden;
    display: block;

    img {
      height: auto;
    }

    @include media(sm) {
      display: none;
    }
  }

  .page_banner__desktop {
    display: none;

    @include media(sm) {
      position: relative;
      width: 100%;
      padding-top: 23.75%;
      overflow: hidden;
      display: block;

      img {
        height: auto;
      }
    }
  }

  &_fullwidth {
    max-width: none !important;
    padding: 0;
    margin: 0;

    .page_banner__desktop {
      @include media(sm) {
        padding-top: 25.78%;
      }
    }

    .overlay__container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .page__banner_text {
      width: 100% !important;
    }

    img {
      @include media(sm) {
        border-radius: 0 !important;
      }
    }
  }

  .overlay__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    @include media(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .overlay {
    position: relative;
    display: block;
    height: 100%;

    &_effect_shadow {
      opacity: 1;
      background-color: transparent;
      background: linear-gradient(180deg, rgba(1, 2, 3, 0) 0%, rgba(1, 2, 3, 0.7) 100%);
    }
  }

  .page__banner_body {
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
    padding: 0 4.6% 8%;
    transform: translate(-50%);

    @include media(sm){
      padding: 0 20px 20px;
    }

    @include media(lg){
      padding: 0 20px 40px;
    }
  }

  .page__banner_text {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    row-gap: 12px;

    @include media(sm) {
      row-gap: 6px;
      width: calc(100% - 50px);
    }

    @include media(md) {
      width: calc(100% - 60px);
    }

    @include media(lg) {
      width: calc(100% - 80px);
    }

    @include media(xl) {
      row-gap: 12px;
      width: calc(100% - 100px);
    }

    @include media(2xl) {
      row-gap: 16px;
      width: calc(100% - 140px);
    }

    @include media(3xl) {
      row-gap: 20px;
      width: calc(100% - 180px);
    }

    .page__banner_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: -webkit-fill-available;
    }

    .page__banner_sharing {
      display: flex;
      flex-wrap: nowrap;
      width: fit-content;
      color: $white;
      padding: 0;
      border: 0;

      button {
        border: 0;

        i {
          color: $white;
        }
      }
    }

    .page__banner_timer{
      color: $white;
      width: 100%;
      font-size: $text-sm;
      line-height: 20px;

      @media screen and (min-width: 428px) {
        font-size: $text-base;
        line-height: 24px;
      }

      @include media(sm){
        font-size: $text-sm;
        line-height: 20px;
      }

      @include media(lg) {
        font-size: $text-base;
        line-height: 24px;
      }

      @include media(xl) {
        font-size: $text-lg;
        line-height: 26px;
      }

      @include media(2xl) {
        font-size: $text-xl;
        line-height: 28px;
      }
    }

    h1 {
      color: $white;
      font-weight: 700;
      font-size: calc(8px + 3vw);
      line-height: calc(14px + 3vw);

      @media screen and (min-width: 520px) {
        font-size: calc(8px + 4vw);
        line-height: calc(14px + 4vw);
      }

      @include media(sm) {
        font-size: $text-xl;
        line-height: 24px;
      }

      @include media(md) {
        font-size: $text-2xl;
        line-height: 28px;
      }

      @include media(lg) {
        font-size: $text-3xl;
        line-height: 34px;
      }

      @include media(xl) {
        font-size: $text-4xl;
        line-height: 40px;
      }

      @include media(2xl) {
        font-size: $text-5xl;
        line-height: 56px;
      }

      @include media(3xl) {
        font-size: $text-6xl;
        line-height: 68px;
      }
    }
  }

  .text__with_maximum_height {
    h1 {
      @include media(2xl) {
        font-size: 2.5rem !important;
        line-height: 48px !important;
      }

      @include media(3xl) {
        font-size: 3rem !important;
        line-height: 56px !important;
      }
    }

    time {      
      @include media(2xl) {
        font-size: $text-lg !important;
        line-height: 24px !important;
      }

      @include media(3xl) {
        font-size: $text-xl !important;
        line-height: 26px !important;
      }
    }
  }
}
