@use "sass:math";
@import '../../styles/variables';
@import '../../styles/mixin';

.collection_container {
  background: inherit;

  .container__no_side_spaces {
    @media screen and (max-width: 480px) {
      margin: 0;
      padding: 0;
    }
  }

  .collection {
    background: inherit;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 4rem;
    min-width: 0;

    &--onSpecials {
      margin-top: 0;
    }

    .collection_hits__list {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      justify-content: center;
      justify-items: center;
      width: 100%;
      grid-row-gap: 16px;
      grid-column-gap: 0px;

      @media screen and (min-width: 540px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        grid-row-gap: 24px;
      }

      @include media(md) {
        grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
      }

      &_card_large {
        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          grid-row-gap: 24px;
        }

        @include media(lg) {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }

        @include media(2xl) {
          grid-row-gap: 36px;
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }

        @include media(3xl) {
          grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
        }
      }

      .collection_hits__list_item {
        width: 100%;
        height: 100%;
      }
    }

    .collection_hits__placeholder {
      display: flex !important;
      width: 100%;
    }

    .collection_hits__footer {
      display: flex;
      gap: 1rem;

      .collection_hits__button {
        background: $white;
        color: $label-btn-secondary;
        height: 40px;
        padding: 11px 16px;
        font-size: $text-sm;
        line-height: 18px;
        text-transform: uppercase;
        margin-top: 3rem;
        border: 1px solid $btn-secondary;
        font-weight: 500;
        min-width: 14rem;

        &.disabled {
          opacity: 0.5;
        }

        &:hover {
          background-image: none;
          border: 1px solid $btn-secondary;
        }
      }
    }

    .collection_pagination {
      margin-top: 50px;
    }

    .collection_load_more {
      margin: 50px auto 0 auto;
      text-transform: uppercase;
      font-size: $text-lg;
      font-weight: 500;
      max-width: 130.79px;
    }

    .hits__empty {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: center;
      padding-top: 78px;
      padding-bottom: 108px;

      i {
        font-size: 46px;
        color: $filter-icon-color;
        margin-bottom: 6px;
      }

      h5 {
        color: $heading-color;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0 auto;
        max-width: 333px;
      }
    }
  }
}

.pagination {
  display: flex;
  gap: 0.3rem;
  margin-top: 3rem;

  @include media(sm) {
    gap: 0.7rem;
    margin-top: 4rem;
  }

  .pagination_item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 28px;
    height: 28px;
    font-size: $text-xs;
    padding: 0.5rem;
    border: 1px solid $gray-200;
    border-radius: 50%;
    cursor: pointer;
    color: $primary;
    font-weight: 400;

    @media screen and (min-width: 360px) {
      width: 30px;
      height: 30px;
    }

    @include media(sm) {
      width: 40px;
      height: 40px;
      font-size: $text-base;
    }

    &.active {
      color: $white;
      background-color: $primary;
      border: none;
    }

    &.disabled {
      background-color: $gray-50;
      color: $gray-200;
      border: 1px solid $gray-50;
      cursor: default;
    }

    a {
      width: 100%;
      height: 100%;
    }
  }
}

.special_description {
  background: $bundle-specials-background;
  border-radius: $bundle-specials-border-radius;
  border: $bundle-specials-border;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  margin: 0 0 20px;
  align-items: center;

  @include media(sm) {
    padding: 32px;
    margin-top: 0;
  }

  @include media(md) {
    margin-top: 0;
  }

  @include media(lg) {
    flex-direction: row;
  }
}

.placeholder__container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .placeholder__banner {
    padding: 0 16px;
    background: $placeholder-bg;
    height: 180px;
    width: 100%;
    border-radius: 4px !important;
    margin: 50px 0 20px;

    @media (max-width: 481px) {
      margin: 20px 15px 20px 15px;
    }

    @include media(md) {
      margin-top: 0;
    }
  }

  .placeholder__product_container {
    flex-grow: 1;
    flex-direction: column;
    padding-bottom: 4rem;

    .placeholder__product_list {
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      justify-content: center;
      justify-items: auto;
      width: 100;
      row-gap: 30px;

      @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        row-gap: 24px;
      }

      @include media(md) {
        grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
      }

      &_card_large {
        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          grid-row-gap: 24px;
        }

        @include media(lg) {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }

        @include media(2xl) {
          grid-row-gap: 36px;
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }

        @include media(3xl) {
          grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
        }
      }
    }

    .placeholder__pagination {
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 4rem;
      column-gap: 16px;
      max-width: 360px;

      .placeholder__container_pages {
        display: flex;
        column-gap: 16px;
        flex-grow: 1;

        .placeholder__page {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
      }

      .placeholder__actions {
        height: 16px;
        width: 30px;
        border-radius: 16px;
      }
    }
  }
}
