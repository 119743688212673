@import '../../styles/variables';
@import '../../styles/mixin';

.aboutbrand {
  padding-top: 64px;
  padding-bottom: 64px;

  .aboutbrand__container {
    padding-top: 16px;
    border-top: 2px solid $primary;
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin: 0 auto;

    @include media(sm) {
      max-width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }

    @include media(lg) {
      max-width: 61.5%;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 12px;
      font-size: $text-base !important;
      text-transform: inherit;
      font-weight: 700;
      line-height: 24px;
      margin-top: 32px;
    }
  }

  .aboutbrand__title {
    margin: 0 auto;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 38px;
    letter-spacing: -0.33px;

    @include media(sm) {
      max-width: 100%;
      font-size: 56px;
      line-height: 62px;
      letter-spacing: -0.5px;
    }

    @include media(lg) {
      max-width: 61.5%;
    }
  }

  .aboutbrand__left,
  .aboutbrand__right {
    @include media(sm) {
      flex-grow: 1;
      width: 50%;
      text-align: left;

      p {
        font-size: $text-sm !important;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }

  .aboutbrand__right {
    :first-child {
      margin-top: 20px;

      @include media(sm) {
        margin-top: 0;
      }
    }

    p {
      margin-top: 0 !important;
    }
  }

  p + p {
    margin: 12px 0;
  }

  figure {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 150px;
  }

  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);

    &:hover {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }

  a {
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 4px 1px;
    background-image: linear-gradient(
      to right,
      rgb(135, 135, 135) 50%,
      transparent 50%
    );

    &:hover {
      color: $label-btn-link;
      font-weight: 500;
      background-image: linear-gradient(
        to right,
        $label-btn-link 50%,
        transparent 50%
      );
    }
  }
}
