@import '../../styles/variables';
@import '../../styles/mixin';

.bundle {
  z-index: 3;
  position: absolute;
  width: 176px;
  bottom: 5px;
  left: -115px;

  @include media(sm) {
    bottom: 45px;
    left: -95px;
  }

  &.on_detail {
    width: auto;
    max-width: 400px;
    bottom: 75px;
    left: -10px;
    right: auto;

    @include media(md) {
      bottom: 75px;
      left: -155px;
    }
  }

  .bundle_panel {
    padding: 1rem;
    border-radius: 0.25rem;
    width: 100%;
    //color: $text-color-bundle-popup;
   /*  background: linear-gradient(
      180deg,
      $background-bundle-popup,
      lighten($background-bundle-popup, 20%)
    ); */
    font-size: $text-xs;
    line-height: 18px;
    font-weight: 500;

    .bundle_panel__close {
      position: absolute;
      right: -7px;
      top: -7px;
      background-color: $white;
      padding: 0.25rem;
      border-radius: 50%;
      color: $action-close-color;
      box-shadow: rgb(149 157 165 / 20%) 0px 0px 6px 2px;

      i {
        font-size: 14px;
      }
    }

    .bundle_panel__title {
      text-align: center;
      margin-bottom: 0.25rem;
      //color: $text-color-bundle-popup;
    }

    .special_title {
      text-align: center;
    }

    &.on_detail {
      .bundle_panel__title {
        text-align: left;
      }
    }

    .bundle_panel__product_link {
      background-position: center bottom;
      background-repeat: repeat-x;
      background-size: 4px 1px;
     /*  background-image: linear-gradient(
        to right,
        $text-color-bundle-popup 50%,
        transparent 50%
      ); */

     /*  &:hover {
        color: $text-color-bundle-popup;
      } */
    }

    &::after {
      content: '';
      //background: lighten($background-bundle-popup, 20%);
      border-radius: 2px;
      bottom: -1rem;
      height: 24px;
      position: absolute;
      left: 50%;
      transform: rotateZ(45deg) translateX(-50%);
      width: 24px;
      z-index: -1;

      @include media(md) {
        left: 58%;
      }
    }
  }

  &.on_detail {
    .bundle_panel {
      @include media(md) {
        font-size: $text-sm;
        line-height: 20px;
      }

      &::after {
        bottom: -0.5rem;
        left: 12%;
        transform: rotateZ(45deg) translateX(0);

        @include media(md) {
          bottom: -1rem;
          left: 50%;
          transform: rotateZ(45deg) translateX(-50%);
        }
      }
    }
  }
}
