@import '../../styles/variables';
@import '../../styles/mixin';

.layout__flex_column {
  display: flex;
  flex-direction: column;
}

.layout__flex_row {
  display: flex;
  flex-direction: row;
}

.socials {
  @extend .layout__flex_row;
  position: relative;
  align-items: center;
  column-gap: 16px;
  margin-top: 12px;
  height: 24px;

  figure {
    width: 24px;
    height: 24px;
    padding-top: 0;
    background-color: transparent;
  }
}

.figure_rectangular {
  position: relative;
  align-self: center;
  width: 100%;
  padding-top: 64.8%;
  overflow: hidden;
  background-color: $gray-50;
  border-radius: 8px;

  img {
    height: auto;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.team__section {
  position: relative;

  .headding {
    font-size: calc(1.1rem + 1.5vw);
    line-height: calc(1.9rem + 1.5vw);
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: 40px;
    }
  }

  .headding__small {
    font-size: $text-sm !important;
    line-height: 20px !important;
    font-weight: 600;
    color: $gray-400;
  }

  .headding__small_16 {
    font-size: $text-base !important;
    line-height: 24px !important;
    font-weight: 400;
    color: $gray-300;
    letter-spacing: -0.02px;
  }

  .headding__medium_20 {
    font-size: $text-xl !important;
    line-height: 28px !important;
    font-weight: 700;
    letter-spacing: -0.02px;
  }

  .headding__medium_24 {
    font-size: $text-2xl !important;
    line-height: 32px !important;
    font-weight: 700;
    color: $black;
  }

  .headding__medium_32 {
    font-size: 2rem !important;
    line-height: 40px !important;
    font-weight: 700;
    color: $black;
  }

  .headding__large_40 {
    font-size: 2rem !important;
    line-height: 40px !important;
    font-weight: 700;

    @include media(xl) {
      font-size: 2.5rem !important;
      line-height: 52px !important;
    }
  }

  &_effect_images {
    figure {
      overflow: hidden;

      &:hover,
      &:focus-within {
        img,
        svg {
          transition: all 0.7s ease-in;
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }

  &_effect_default_images {
    figure {
      &:hover,
      &:focus-within {
        img,
        svg {
          transition: all 0.3s ease-in;
          opacity: 0.8;
        }
      }
    }
  }

  figure {
    position: relative;
    width: 106px;
    height: 106px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }

    svg {
      width: 106px;
      height: 106px;
    }
  }

  .team__header {
    @extend .layout__flex_column;
    max-width: 604px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    row-gap: 12px;
  }

  div[class~='swiper-pagination'] {
    display: block;
    bottom: 0;

    @include media(xl) {
      display: none;
    }
  }

  button[class~='slick-prev'],
  button[class~='slick-next'] {
    display: none;

    @include media(xl) {
      display: block;
      position: absolute !important;
      top: 40%;
      transform: translateY(-40%);
      width: 48px;
      height: 48px;
      border: 0;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.45);
      z-index: 1;
    }
  }

  button[class~='slick-prev'] {
    left: 0px;

    &::before {
      content: '\e804';
    }

    &::after {
      display: none;
    }
  }

  button[class~='slick-next'] {
    right: 10px;

    &::before {
      content: '\e805';
    }
  }

  div[class~='swiper'] {
    > div {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    padding-bottom: 0;
  }

  .team__container {
    @extend .layout__flex_column;
    row-gap: 56px;
    width: 100%;
  }

  &_carousel_members_default {
    .team__carousel_container {
      @extend .layout__flex_column;
      row-gap: 40px !important;

      @include media(xl) {
        margin: 0 auto;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;
        column-gap: 24px;
        max-width: 780px;

        section {
          max-width: 520px;
        }
      }

      div[class~='swiper'] {
        padding-bottom: 40px;

        @include media(xl) {
          padding-bottom: 0;
        }
      }

      .teammate__images {
        @extend .layout__flex_row;
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: $background-theme;

        @include media(xl) {
          max-width: 460px;
        }

        figure {
          position: relative;
          min-width: 140px;
          height: 140px;
          border-radius: 50%;

          @media screen and (max-width: 427px) {
            min-width: 90px;
            height: 90px;
          }

          @include media(xl) {
            min-width: 128px;
            height: 128px;
          }

          img {
            border-radius: 50%;
          }

          svg {
            min-width: 140px;
            height: 140px;

            @media screen and (max-width: 427px) {
              min-width: 90px;
              height: 90px;
            }

            @include media(xl) {
              min-width: 128px;
              height: 128px;
            }
          }
        }

        .teammate__before {
          left: 45px;

          @media screen and (max-width: 427px) {
            left: 25px;
          }

          @include media(sm) {
            left: 60px;
          }
        }

        .teammate__after {
          right: 45px;

          @media screen and (max-width: 427px) {
            right: 25px;
          }

          @include media(sm) {
            right: 60px;
          }
        }

        .teammate_wrapper {
          padding: 10px;
          border-radius: 50%;
          z-index: 1;
          background-color: inherit;
        }

        .teammate__main {
          z-index: 1;
          width: 140px;
          height: 140px;

          @media screen and (max-width: 330px) {
            width: 120px;
            height: 120px;
          }

          @media screen and (min-width: 428px) {
            width: 190px;
            height: 190px;
          }

          @include media(xl) {
            min-width: 236px;
            height: 236px;
          }

          svg {
            width: 140px;
            height: 140px;

            @media screen and (min-width: 428px) {
              width: 190px;
              height: 190px;
            }

            @include media(xl) {
              min-width: 236px;
              height: 236px;
            }
          }
        }
      }

      .team__carousel {
        .teammate__info_body {
          @extend .layout__flex_column;
          margin: 0 auto;
          row-gap: 8px;
          align-items: center;
          text-align: center;
          min-width: 293px;
          width: 73.62%;

          @include media(xl) {
            padding-bottom: 0;
            min-width: 366px;
            align-items: flex-start;
            text-align: left;
          }

          p {
            margin-top: 0;
            line-height: 24px;
          }
        }
      }
    }
  }

  &_left_aligned_content {
    .team__body {
      position: relative;
      display: grid;
      justify-content: center;
      column-gap: 24px;
      row-gap: 36px;
      width: 100%;

      @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
      }

      @include media(xl) {
        grid-template-columns: repeat(5, minmax(226px, 1fr));
      }

      .team__teammate_info {
        @extend .layout__flex_column;
        margin: 0 auto;
        row-gap: 12px;
        max-width: 396px;

        @include media(2xl) {
          max-width: 307px;
        }

        @include media(3xl) {
          max-width: 380px;
        }

        .teammate__info_body {
          @extend .layout__flex_column;
          align-items: center;
          text-align: center;
          order: 1;
          row-gap: 4px;

          h3 {
            @extend .headding__medium_20;
          }

          p {
            margin-top: 12px;
          }

          @include media(xl) {
            align-items: flex-start;
            text-align: left;
          }
        }

        figure {
          align-self: center;

          @include media(xl) {
            align-self: flex-start;
          }
        }
      }
    }
  }

  &_center_aligned_content {
    .team__container {
      row-gap: 40px;

      @include media(sm) {
        row-gap: 48px;
      }

      .team__header {
        max-width: 396px;

        @include media(sm) {
          max-width: 516px;
        }
      }
    }

    .team__body {
      @extend .layout__flex_row;
      margin: 0 auto;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 36px;
      max-width: 1036px;

      @include media(md) {
        column-gap: 24px;
        row-gap: 32px;
      }

      .team__teammate_info {
        @extend .layout__flex_column;
        min-width: 138px;
        flex-grow: 1;
        row-gap: 12px;
        flex-basis: 33%;
        max-width: 236px;

        .teammate__info_body {
          order: 1;
          align-items: center;
          text-align: center;

          p {
            margin-top: 12px;
          }
        }

        figure {
          order: 0;
          align-self: center;
        }
      }
    }
  }

  &_3columns {
    .team__header {
      @include media(xl) {
        max-width: 754px;
      }
    }

    .team__body {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      column-gap: 24px;
      row-gap: 40px;
      width: 100%;

      @include media(2xl) {
        column-gap: 32px;
      }

      @include media(3xl) {
        column-gap: 40px;
      }

      .team__teammate_info {
        @extend .layout__flex_column;
        flex-grow: 1;
        row-gap: 12px;
        max-width: 395px;

        @include media(sm) {
          max-width: 288px;
          flex-basis: 45%;
        }

        @include media(lg) {
          max-width: 395px;
          flex-basis: 33%;
        }

        .teammate__info_body {
          @extend .layout__flex_column;
          row-gap: 4px;
          align-items: center;
          text-align: center;
          row-gap: 8px;
          order: 1;

          p {
            margin-top: 12px;
          }

          .teammate__socials {
            @extend .socials;
          }
        }

        figure {
          @extend .figure_rectangular;
          order: 0;
        }
      }
    }
  }

  &_4columns {
    .team__body {
      margin: 0 auto;
      display: grid;
      justify-content: center;
      column-gap: 24px;
      row-gap: 40px;
      width: 100%;
      max-width: 1240px;

      @include media(sm) {
        max-width: 640px;
        grid-template-columns: repeat(2, minmax(290px, 1fr));
      }

      @include media(xl) {
        max-width: 1264px;
        column-gap: 32px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @include media(xl) {
        max-width: 1300px;
        column-gap: 40px;
      }

      .team__teammate_info {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        max-width: 290px;

        @include media(sm) {
          max-width: 290px;
        }

        .teammate__info_body {
          @extend .layout__flex_column;
          row-gap: 4px;
          align-items: center;
          text-align: center;
          row-gap: 8px;
          order: 1;

          p {
            margin-top: 12px;
          }

          .teammate__socials {
            @extend .socials;
          }
        }

        figure {
          @extend .figure_rectangular;
          padding-top: 110.35%;
          order: 0;
        }
      }
    }
  }

  &_carousel {
    .team__carousel_container {
      @extend .layout__flex_column;
      row-gap: 40px;

      @include media(xl) {
        margin: 0 auto;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        column-gap: 24px;
        max-width: 920px;
      }

      div[class~='swiper'] {
        padding-bottom: 40px;
        margin: 0 auto;

        @include media(xl) {
          padding-bottom: 0;
        }
      }

      button[class~='slick-prev'],
      button[class~='slick-next'] {
        display: none;

        @include media(xl) {
          display: block;
          position: absolute !important;
          top: 45%;
          transform: translateY(-40%);
          width: 48px;
          height: 48px;
          border: 0;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.45);
          z-index: 1;
        }
      }

      button[class~='slick-prev'] {
        left: 0px;
      }

      button[class~='slick-next'] {
        right: 0px;
      }

      .team__carousel {
        .team__teammate_info {
          margin: 0 auto;
          @extend .layout__flex_column;
          row-gap: 40px;

          @include media(xl) {
            flex-direction: row;
            column-gap: 24px;
          }
        }

        .teammate__info_body {
          @extend .layout__flex_column;
          order: 1;
          margin: 0 auto;
          row-gap: 8px;
          align-items: center;
          text-align: center;
          max-width: 496px;

          @include media(xl) {
            padding-bottom: 0;
            align-items: flex-start;
            text-align: left;
          }

          p {
            margin-top: 16px;
            line-height: 24px;
          }

          a {
            margin-top: 16px;
          }
        }

        figure {
          margin: 0 auto;
          order: 0;
          width: 190px;
          height: 190px;

          @include media(xl) {
            width: 236px;
            height: 236px;
          }

          svg {
            width: 190px;
            height: 190px;

            @include media(xl) {
              width: 236px;
              height: 236px;
            }
          }
        }
      }
    }
  }

  &_member_right {
    .team__carousel_container {
      @extend .layout__flex_column;
      row-gap: 40px;

      @include media(xl) {
        margin: 0 auto;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        column-gap: 24px;
        max-width: 1024px;
      }

      div[class~='swiper'] {
        padding-bottom: 0;
        margin: 0 auto;
      }

      div[class~='swiper-pagination'] {
        display: none;
      }

      button[class~='slick-prev'],
      button[class~='slick-next'] {
        display: none;

        @include media(xl) {
          display: block;
          position: absolute !important;
          top: 45%;
          transform: translateY(-40%);
          width: 48px;
          height: 48px;
          border: 0;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.45);
          z-index: 1;
        }
      }

      button[class~='slick-prev'] {
        left: 0px;
      }

      button[class~='slick-next'] {
        right: 0px;
      }

      .team__carousel {
        .team__teammate_info {
          margin: 0 auto;
          @extend .layout__flex_column;
          row-gap: 40px;

          @include media(xl) {
            flex-direction: row;
            align-items: center;
            column-gap: 24px;
          }
        }

        .teammate__info_body {
          @extend .layout__flex_column;
          order: 1;
          margin: 0 auto;
          row-gap: 8px;
          align-items: center;
          text-align: center;
          max-width: 496px;

          @include media(xl) {
            padding-bottom: 0;
            align-items: flex-start;
            text-align: left;
          }

          p {
            margin-top: 16px;
            line-height: 24px;
          }

          .teammate__socials {
            @extend .socials;
          }
        }

        figure {
          margin: 0 auto;
          width: 293px;
          height: 293px;

          @include media(xl) {
            width: 366px;
            height: 366px;
          }

          svg {
            width: 293px;
            height: 293px;

            @include media(xl) {
              width: 366px;
              height: 366px;
            }
          }
        }
      }
    }

    .team__carrousel_thumbs {
      width: 310px;
      height: 100%;

      @include media(xl) {
        padding-left: 0;
        width: 120px;
        height: 366px;
      }

      > div {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }

      figure {
        width: 80px !important;
        height: 80px !important;

        svg {
          width: 80px !important;
          height: 80px !important;
        }

        @include media(xl) {
          width: 106px !important;
          height: 106px !important;

          svg {
            width: 106px !important;
            height: 106px !important;
          }
        }
      }
    }
  }
}
