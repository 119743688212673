@import '../../../styles/variables';
@import '../../../styles/mixin';

.stats__container {
  display: flex;
  flex-direction: column;

  .stats__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #494d54;
    margin-bottom: 40px;
    max-width: 600px;

    @include media(sm) {
      font-size: 32px;
      line-height: 40px;
      color: #494d54;
      margin-bottom: 72px;
    }
  }

  .stats__list {
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    height: auto;
    align-items: center;
    margin-bottom: 48px;
    padding-inline: 24px;
    padding-block: 48px;
    align-self: center;

    @include media(md) {
      flex-direction: row;
      padding-inline: 80px;
      height: 336px;
    }

    @media screen and (min-width: 250px) {
      font-size: 64px;
    }

    .stats__separator {
      height: var(--separator-width);
      width: 100%;
      margin-inline: 0px;
      margin-block: 48px;

      @include media(md) {
        height: 240px;
        width: var(--separator-width);
        margin-block: 0px;
        margin-inline: 91px;
      }
    }

    .stats__element {
      display: flex;
      flex-direction: column-reverse;

      .stats__element_title {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #898c91;
        max-width: 240px;

        @include media(md) {
          max-width: 340px;
        }
      }

      .stats__element_value {
        font-weight: 700;
        line-height: 78px;
        font-size: 48px;
        color: #494d54;

        @media screen and (min-width: 250px) {
          font-size: 64px;
        }
      }
    }
  }

  .stats__bottom_description {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    max-width: 800px;
    color: #898c91 !important;
  }
}
