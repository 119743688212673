@import '../../../styles/variables';
@import '../../../styles/mixin';

.stats__container {
  display: flex;
  flex-direction: column;

  .stats__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #494d54;
    margin-bottom: 40px;
    max-width: 600px;

    @include media(sm) {
      font-size: 32px;
      line-height: 40px;
      color: #494d54;
      margin-bottom: 72px;
    }
  }

  .stats__list {
    display: flex;
    flex-direction: row;
    align-self: center;
    max-width: fit-content;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 40px;

    .stats__element {
      display: flex;
      flex-direction: column;

      .stats__element_title {
        order: 1;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #62666e;
        margin-bottom: 10px;
      }

      .stats__element_description {
        order: 2;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        max-width: 395px;
        color: #898c91;
      }

      .stats__element_header {
        order: 0;
        display: flex;
        align-items: start;

        .stats__element_value {
          font-size: 48px;
          font-weight: 700;
          line-height: 78px;
          color: #494d54;
          margin-bottom: 8px;
          margin-right: 12px;

          @media screen and (min-width: 250px) {
            font-size: 64px;
          }
        }

        .stats__element_image {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .stats__bottom_description {
    margin-top: 48px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    max-width: 800px;
    color: #898c91 !important;
  }
}
