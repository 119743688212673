@import '../../styles/variables';
@import '../../styles/mixin';

.spacing_bottom {
  margin-bottom: $margin-section-mobile;

  @include media(lg) {
    margin-bottom: $margin-section;
  }
}
