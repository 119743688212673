@import '../../../styles/variables';
@import '../../../styles/mixin';

.stats__container {
  display: flex;
  flex-direction: column;

  .stats__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #494d54;
    margin-bottom: 40px;
    max-width: 600px;

    @include media(sm) {
      font-size: 32px;
      line-height: 40px;
      color: #494d54;
      margin-bottom: 72px;
    }
  }

  .stats__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 45px;
    column-gap: 45px;
    justify-content: space-evenly;

    .stats__element {
      display: flex;
      flex-direction: column;

      .stats__element_title {
        order: 2;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #898c91;
        margin-bottom: 24px;
      }
      .stats__element_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        &_row {
          flex-direction: row;
          margin-bottom: 24px;
          justify-content: left;
          gap: 1rem;
        }
      }

      .stats__element_value {
        order: 1;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #494d54;
        margin-bottom: 4px;
        margin-top: 16px;
      }

      .stats__element_description {
        order: 3;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        max-width: 260px;
        color: #494d54;
      }

      .stats__element_image {
        width: 80px;
        height: 80px;
      }
    }
  }
}
