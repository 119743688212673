@import '../../styles/variables';
@import '../../styles/mixin';

.page_banner__section {

  &_fullwidth {
    padding: 0;
    margin: 0;
  }

  &__spacing_bottom {
    margin-bottom: $margin-section-mobile;

    @include media(lg) {
      margin-bottom: $margin-section;
    }
  }

  &__with_side_image_spacing_bottom {
    margin-bottom: calc($margin-section-mobile + var(--extra-spacing));

    @media screen and (max-width: 1024px) {
      margin-bottom: calc($margin-section + var(--mobile-extra-spacing) - 10px);
    }

    @include media(lg) {
      margin-bottom: calc($margin-section + var(--extra-spacing) - 10px);
    }
  }

  &__extra_spacing {
    margin-bottom: var(--extra-spacing);

    @media screen and (max-width: 1024px) {
      margin-bottom: var(--mobile-extra-spacing);
    }
  }

  .placeholder {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: $placeholder-bg;

    div {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      margin: 0 auto;
      height: 18px;
      width: 18px;

      @include media(3xl) {
        height: 30px;
        width: 30px;
      }
    }

    p {
      width: 100%;
      margin-top: 10px;
      text-align: center;
      font-size: $text-xs;
      color: $gray-300 !important;

      @include media(3xl) {
        font-size: $text-base;
      }
    }
  }
}

.page_banner__container {
  position: relative;
  background: inherit;

  @media screen and (max-width: 640px) {
    padding: 0;
    margin: 0;
  }

  .page_banner__mobile {
    position: relative;
    width: 100%;
    padding-top: 58.13%;
    overflow: hidden;
    display: block;

    img {
      height: auto;
    }

    @include media(sm) {
      display: none;
    }
  }

  .page_banner__desktop {
    display: none;

    @include media(sm) {
      position: relative;
      width: 100%;
      padding-top: 23.75%;
      overflow: hidden;
      display: block;

      img {
        height: auto;
      }
    }
  }

  &_fullwidth {
    max-width: none !important;
    padding: 0;
    margin: 0;

    .page_banner__desktop {
      @include media(sm) {
        padding-top: 22.27%;
      }
    }

    .overlay__container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    img {
      @include media(sm) {
        border-radius: 0 !important;
      }
    }
  }

  .overlay__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    @include media(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .overlay {
    position: relative;
    display: block;
    height: 100%;
  }

  .page__banner_body {  
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;

    @include media(sm) {
      padding: 20px;
    }

    @include media(lg) {
      padding: 20px;
    }  
  }

  .page__aside_image {
    position: absolute;
    background-color: $background-page;
    border: var(--border-dimension) solid var(--border-color);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 16px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      height: 76px !important;
      width: 76px !important;
      border-width: 2px !important;
    }

    &__bottom_start {
      right: auto;
      top: auto;
      bottom: 0;
      left: 0;
      transform: translate(50%, 50%);
    }

    &__bottom_end {
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
      transform: translate(-50%, 50%);
    }

    &__bottom_center {
      right: auto;
      top: auto;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .page__banner_text {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    br {
      display: none;
    }

    @include media(sm) {
      row-gap: 6px;
      width: calc(100% - 50px);
    }

    @include media(md) {
      width: calc(100% - 60px);
    }

    @include media(lg) {
      row-gap: 16px;
      width: calc(100% - 80px);

      br {
        display: block;
      }
    }

    @include media(xl) {
      width: calc(100% - 100px);
    }

    @include media(2xl) {
      row-gap: 20px;
      width: calc(100% - 140px);
    }

    @include media(3xl) {
      row-gap: 20px;
      width: calc(100% - 180px);
    }

    .page__banner_description {
      width: 100%;
      font-size: $text-xs;

      @media screen and (min-width: 428px) {
        font-size: $text-sm;
      }

      @media screen and (min-width: 520px) {
        font-size: $text-base;
      }

      @include media(sm) {
        font-size: $text-xs;
      }

      @include media(md) {
        font-size: $text-sm;
      }

      @include media(lg) {
        font-size: $text-base;
        line-height: 20px;
      }

      @include media(xl) {
        font-size: calc(3px + 1vw);
        line-height: calc(8px + 1.1vw);
      }
    }

    h1,
    h2 {
      font-weight: 700;
      font-size: calc(8px + 4vw);
      line-height: calc(14px + 4vw);

      @include media(sm) {
        font-size: $text-base;
        line-height: 20px;
      }

      @include media(md) {
        font-size: calc(10px + 2vw);
        line-height: calc(18px + 2vw);
      }
    }

    .banner_content__action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      font-size: $text-sm;
      letter-spacing: 0.8px;
      padding: 5px 16px;
      min-width: 100px;
      font-weight: 700;
      height: 36px;
      background-color: var(--cta-background-color);
      color: var(--cta-text-color);
      border: var(--border-style);
      border-radius: var(--border-radius);
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--cta-background-color-hover);
        color: var(--cta-text-color-hover);
        border: var(--border-style-hover);
      }

      @media screen and (max-width: 420px) {
        padding: 2px 10px;
        font-size: $text-xs;
        height: 30px;
      }

      @include media(sm) {
        padding: 2px 10px;
        height: 30px;
      }

      @include media(lg){
        padding: 9px 16px;
        height: 36px;
      }

      @include media(xl) {
        height: 48px;
        margin-top: 8px;  
        font-size: $text-base;
        line-height: 22.39px;
        min-width: 160px;
        padding: 8px 16px;
      }

      &_hidde_mobile {
        display: none;

        @include media(lg){
           display: flex;
        }
      }
    }
  }

  .text__with_maximum_height {
    h1 {
      @include media(2xl) {
        font-size: 2.5rem !important;
        line-height: 48px !important;
      }

      @include media(3xl) {
        font-size: 3rem !important;
        line-height: 56px !important;
      }
    }

    p {
      @include media(2xl) {
        font-size: $text-lg !important;
        line-height: 24px !important;
      }

      @include media(3xl) {
        font-size: $text-xl !important;
        line-height: 26px !important;
      }
    }
  }

  .text__align_centered_mobile {
    @media screen and (max-width: 640px) {
      text-align: center !important;
      align-items: center !important;
    }
  }
}
