@import '../../styles/variables';
@import '../../styles/mixin';

.banner_feature__section {
  position: relative;
  padding-top: 24px;

  @include media(lg){
    padding-top: 40px;
  } 

  h2 {
    font-size: 2rem;
    font-weight: 500px;
    line-height: 40px;
  }

  .banner_feature__container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 100%;
 
    @include media(lg){
      display: grid;
      padding-top: 32px;
      gap: 24px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 380px);
    }

    @include media(xl){
      grid-template-columns: minmax(0, 1fr) minmax(0, 450px);
    }

    @include media(3xl){
      grid-template-columns: minmax(0, 1fr) minmax(0, 520px);
    }
  }

  .banner_feature__carousel {    
    span[class~='swiper-pagination-bullet'] {
      background-color: $swiper-bullet; /* Cambia el color del bullet */
    }

    span[class~='swiper-pagination-bullet-active'] {
      background-color: $swiper-bullet-active !important; /* Cambia el color del bullet activo */
    }
  }

  .banner_feature__main_banner {
    width: 100%;

    .placeholder {
      position: relative;
      width: 100%;
      overflow: hidden;
      border-radius: $image-border-radius;
      background-color: $placeholder-bg;

      div {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      svg {
        margin: 0 auto;
        height: 18px;
        width: 18px;

        @include media(3xl) {
          height: 30px;
          width: 30px;
        }
      }

      p {
        width: 100%;
        margin-top: 10px;
        text-align: center;
        font-size: $text-xs;
        color: $gray-300 !important;

        @include media(3xl) {
          font-size: $text-base;
        }
      }
    }
  }

  .figure {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .main_banner__mobile {       
    padding-top: 58.13%;
    display: block; 

    @include media(sm) {
      display: none;
    }

    img {
      height: auto;
      border-radius: $image-border-radius !important;
    }
  }

  .main_banner__desktop {
    display: none;

    @include media(sm) {
      padding-top: 43.20%;
      display: block;
    }

    img {
      height: auto;
      border-radius: $image-border-radius !important;
    }
  }

  .overlay__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .overlay {
    position: relative;
    display: block;
    height: 100%;

    &_effect_shadow {
      opacity: 1;
      border-radius: $image-border-radius;
      background-color: transparent;
      background: linear-gradient(180deg, rgba(1, 2, 3, 0) 0%, rgba(1, 2, 3, 0.9) 100%);
    }
  }

  .main__banner_body {
    position: absolute;
    width: -webkit-fill-available;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  .main__banner_text {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    row-gap: 12px;
    padding: 0 24px 24px;

    @media screen and (max-width: 420px) {
      row-gap: 8px;
      padding: 0 14px 18px;
    }

    @include media(2xl) {
      padding: 0 36px 36px;
    }

    .main__banner_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: -webkit-fill-available;
    }

    .main__banner_sharing {
      display: flex;
      flex-wrap: nowrap;
      width: fit-content;
      color: $white;
      padding: 0;
      border: 0;

      button {
        border: 0;
        color: $white;
      }

      @media screen and (max-width: 1535px) {
        width: 80px;
        color: $white;
        border: 0;

        button {
          border: 0;
          font-size: 16px;    
        }

        button:first-child {
          padding-right: 10px;
        }
        
        button:nth-child(2) {
          padding:0 10px;
        }

        button:last-child {
          padding-left: 10px;
        }
      }
    }

    .main__banner_timer{
      color: $white;
      width: 100%;
      font-size: $text-sm;
      line-height: 20px;

      @media screen and (max-width: 428px) {
        font-size: $text-xs;
        line-height: 20px;
      }

      @include media(lg) {
        font-size: $text-base;
        line-height: 24px;
      }

      @include media(2xl) {
        font-size: $text-lg;
        line-height: 26px;
      }

      @include media(3xl) {
        font-size: $text-xl;
        line-height: 28px;
      }
    }

    h1 {
      color: $white;
      font-weight: 500;
      font-size: calc(8px + 3vw);
      line-height: calc(14px + 3vw);

      @media screen and (min-width: 520px) {
        font-size: calc(8px + 3.4vw);
        line-height: calc(14px + 3.4vw);
      }

      @include media(sm) {
        font-size: $text-2xl;
        line-height: 28px;
      }

      @include media(xl) {
        font-size: 2rem;
        line-height: 36px;
      }

      @include media(2xl) {
        font-size: $text-4xl;
        line-height: 44px;
      }

      @include media(3xl) {
        font-size: $text-5xl;
        line-height: 56px;
      }
    }
  }

  .banner_feature__carrousel_thumbs {
    overflow: hidden;
    height: fit-content;
    max-height: 380px;
    width: 100%;

    @include media(lg){
      padding-left: 16px;
      height: 250px;
    }

    @include media(xl){
       height: 330.91px;
    }

    @include media(2xl){
       height: 100%;
       max-height: 441.5px;
    }

    @include media(3xl){
       max-height: 632.44px;
    }

    .banner_feature__related_post_article {
      a {
        display: grid;
        grid-template-columns: minmax(0, 90px) minmax(0, 1fr);
        gap: 24px;
        width: 100%;

        @include media(2xl){
          grid-template-columns: minmax(0, 100px) minmax(0, 1fr);
          gap: 32px;
        }

        @include media(3xl){
          grid-template-columns: minmax(0, 115px) minmax(0, 1fr);
        }

      }

      figure {
        width: 80px;
        height: 80px;
        padding-top: 0;
        margin-bottom: 0;
        border-radius: $image-border-radius;

        @include media(2xl){
          width: 100px;
          height: 100px;
        }

        @include media(3xl){
          width: 115px;
          height: 115px;
        }
        
        i {
          font-size: 12px;
        }
        
      }

      h5 {
        order: 1;
        font-size: $text-base !important;
        font-weight: 500;
        line-height: 20px;

        @include media(2xl){
          font-size: $text-lg !important;
          line-height: 24px;
        }

        @include media(3xl){
          font-size: $text-xl !important;
          line-height: 28px;
        }
      }

      time {
        order: 2;
      }

      p, div[class~='sharing_sharing__6DiP9'] {
        display: none;
      }

      div[class~='article_article__body__SUIxL'] {
        gap: 8px;
        width: max-content;
      }
    }
  }
}

.placeholder__blog_featured {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;

  @include media(lg){
    display: grid;
    padding-top: 24px;
    gap: 24px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 380px);
  }

  @include media(xl){
    grid-template-columns: minmax(0, 1fr) minmax(0, 450px);
  }

  @include media(3xl){
    grid-template-columns: minmax(0, 1fr) minmax(0, 520px);
  }

  .placeholder__blog_banner {  
    position: relative;
    background-color:$placeholder-bg;
    width: 100%;
    padding-top: 58.13%;
    border-radius: $image-border-radius;
    overflow: hidden;

    @include media(sm) {
      padding-top: 43.20%;
    }

    img {
      height: auto;
      border-radius: $image-border-radius !important;
    }
  }

  ol {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 380px;
    width: 100%;

    @include media(lg){
      height: 250px;
    }

    @include media(xl){
       height: 330.91px;
    }

    @include media(2xl){
       height: 441.5px;
    }

    @include media(3xl){
       height: 632.44px;
    }

    li {   
      display: grid;
      grid-template-columns: minmax(0, 90px) minmax(0, 1fr);
      gap: 16px;
      width: 100%;

      @include media(2xl){
        grid-template-columns: minmax(0, 100px) minmax(0, 1fr);
        gap: 32px;
      }

      @include media(3xl){
        grid-template-columns: minmax(0, 115px) minmax(0, 1fr);
      }

      figure {
        width: 80px;
        height: 80px;
        background-color: $placeholder-bg-200;
        border-radius: $image-border-radius;

        @include media(2xl){
          width: 100px;
          height: 100px;
        }

        @include media(3xl){
          width: 115px;
          height: 115px;
        }
        
      }

      h5 {
        order: 1;
        width: 90%;
        height: 28px;
        background-color: $placeholder-bg;
      }

      p {
        width: 50%;
        height: 16px;
        margin-top: 8px;
        background-color: $placeholder-bg-300;
      }
    }
  }

  li:nth-last-of-type(3)  {
    width: 100%;
  }

  li:nth-last-of-type(2)  {
    width: 79%;
  }

  &_dark{
    .placeholder__blog_banner { 
      background-color: $placeholder-bg-dark-200;
    } 

    figure {
      background-color: $placeholder-bg-dark-300
    }

    h5 {
      background-color: $placeholder-bg-dark-100 !important;
    }

    p {
      background-color: $placeholder-bg-dark-300 !important;
    }

  }
}

  